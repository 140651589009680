
@import url('https://fonts.googleapis.com/css2?Roboto:wght@400;500;700&display=swap');

body, html{
  background-color: #DCDCDC;
  padding: 0px;
  margin: 0px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  height: 100%;
}
.st0{fill:#EB8924;}
.st1{fill:#DA5A27;}
.st2{fill:#8B5E3C;}
#panregiones{
  display: flex;
  flex-direction: column;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

#pager {  
  animation: 1s ease-out 0s 1 slideInFromLeft;
}

#pager{
  display: flex;
}
#pager button{
  padding: 5px;
  flex: 1;
}
#pager button.activo{
  background-color: #000;
  color: #c5c5c5;
}

#panregiones #regiones{
  padding: 0px;
  margin: 0px;
  flex: 1;
  overflow: auto;
}
#panregiones .autocompletar{
  background-color: #DCDCDC;
}
#panregiones #current{
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: center;
}
#panregiones #current .titulo{
  text-transform:uppercase;
  font-weight: bold;
  background-color: #000;
  color: #c5c5c5;
  padding: 3px;
}
#panregiones .current{
  background-color: #DA5A27;
  color: #c5c5c5;
  font-size: 30px;
}
#panregiones #regiones>li ul{
  height: 100%;
  transform-origin: top;
  transition: transform .2s ease-out;
  transform: scaleY(1);
  padding: 0px 0px;
  margin: 0px;
}
.scroller{
  overflow: auto!important;
}
#panregiones #regiones li{
  list-style: none;
  /*background-color: #DCDCDC;*/
}
#panregiones #regiones li.inactivo{
  display: none;
  /*background-color: #DCDCDC;*/
}
#panregiones #regiones>li li{
  margin-right:10px;
  width: auto;
}
#panregiones #regiones li .region{
  display: flex;
  background-color: #DCDCDC;
  border-radius: 0px 0px 10px 0px;
  border-bottom: #000 1px solid;
  border-right: #000 1px solid;
}
#panregiones #regiones li .region.tienda{
  border-radius: 0px;
}
#panregiones #regiones li .region button{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  font-size: 22px;
}
#panregiones #regiones .graficar{
  display: flex;
  align-items:center;
  justify-content:flex-start;
  padding: 5px;
  flex: 1;
}
#panregiones #regiones li .region .toggle{
  border-left: rgb(171, 171, 171) 1px solid;
  background-color: #c5c5c5;
  border-radius: 0px 0px 10px 0px;
}
#panregiones #regiones .toggle.activo{
  background-color: #000!important;
  color: #FFF;
}
#panregiones #regiones .toggle.activo .contraer{
  display: inline-block;
}
#panregiones #regiones .toggle:not(.activo) .contraer{
  display: none;
}
#panregiones #regiones .toggle:not(.activo) .expandir{
  display: inline-block;
}
#panregiones #regiones .toggle.activo .expandir{
  display: none;
}
#panregiones #regiones .graficar.activo{
  background-color: rgb(0, 171, 40);
  color: #FFF;
}
#panregiones #regiones ul.colapsar{
  height: 0;
  transform: scaleY(0);
}
#panregiones #regiones .escalon{
  background-color: #c5c5c5!important;
}
#panregiones #regiones .familia{
  background-color: #94d8a4!important;
}
#panlanguage .activo{
  background-color: #DA5A27;
}

button{
  padding: 1px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
}
#splash{
  background-color: #DCDCDC;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: calc(100vh - calc(100vh - 100%));
  transition: all 1s;
  top:0px;
  left:0px;
  z-index: 1000;
}
#splash.gone{
  top: -100vh;
}
#splash svg{
  margin: auto;
}
#login{
  background-color: #DCDCDC;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: calc(100vh - calc(100vh - 100%));
  transition: all 1s;
  top:0px;
  left:0px;
}
#login .logo{
 max-width: 300px;
 max-height: 130px;
 margin-bottom: 20px;
}
#login .logocliente{
  border-radius: 10px;
  box-shadow: -0px 2px 5px 0px rgba(0,0,0,0.1);
 }
#login #leyenda, #login .formErrors {
  max-width: 300px;
  width: 100%;
  text-align: center;
}
.formErrors{
  color: #e71515;
  font-weight: bold;
  width: 100%;
}
#login #leyenda strong{
  font-weight: bold;
}
#login>div:not(#leyenda){
  max-width: 300px;
  display: flex;
  align-items: stretch;
  flex-direction: column;
 }
 #root #login>#instrucciones{
  display: flex;
  width: 300px;
}
#root #login>#instrucciones>div{
flex: 1;
font-weight: normal;
font-size: 14px;
background-color: #FFF;
}
.react-tabs__tab-list {
  margin: 0px!important;
}
 #login form{
  width: 100%;
  max-width: 300px;
 }
#login form>div{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  flex: 1;

 }
 #login div span{
  flex: 0.8;
 }
 #login div input, #login div button{
  flex: 1.2;
 }
 #login .danger{
   width: 50px;
   height: 50px;
 }
 .danger path{
   fill: #e71515;
 }
 #login button{
   margin-top: 15px;
  background-color: #000;
  color: #c5c5c5;
  padding: 5px;
 }
#root{
  height: calc(100vh - calc(100vh - 100%));
  display: flex;
  flex-direction: column;
}
.contenedor{
  flex:3;
  display: flex;
  overflow: auto;
}
.contenedor .contenedorgrafica{
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  flex: 1;
}
#graficas{
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}
.contenedorgrafica .grafica{
  flex: 1;
  display: flex;
}
#footer{
 display: flex;
 flex-direction: column;
 align-items:stretch;
 justify-content: stretch;
 background-color: #EB8924;
 flex: 0.8;
}
#footer #foot{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #FFF;
 }
 #footer #sucursales{
   display: flex;
   align-items: center;
   justify-content: center;
   flex: 1;
 }
 #footer #sucursales #bregiones{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}
 #meses, #meses .semanas{
   display: flex;
  flex-wrap: wrap;
  background-color: #DA5A27;
  justify-content: space-evenly;
}
 #meses>button, #meses>.mes, #meses .semanas>button, #meses .semanainactiva{
   text-transform: uppercase;
   color: #c5c5c5;
   flex: 1;
 }
 #meses button, #meses .semanainactiva{
   width: 100%;
   padding: 5px;
 }
 #meses .semanainactiva{
    background-color: #e79e67;
    color: #cbcbcb;
    font-size: 14px;
    text-align: center;
 }
 #meses button:hover{
  background-color: #8B5E3C;
}
#meses .next{
  background-color: rgba(255,0,0,0.4);
  color: #c5c5c5;
}
#meses .activo{
  background-color: #000;
  color: #c5c5c5;
}
#footer #logos{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#footer #logo{
  height: 50px;
  width: auto;
  padding: 10px;
  padding-left: 20px;
}
#footer #logocliente{
  height: 50px;
  width: auto;
  padding: 5px;
}
#footer #logocliente img{
  height: 40px;
  width: auto;
}
#footer #menu{
  padding: 10px;
  padding-right: 20px;
}
#rango{
  width:calc(100% - 100px);
  margin: 0px auto;
}
#rango .MuiSlider-rail{
  background: rgb(253,102,95);
background: linear-gradient(90deg, rgba(253,102,95,1) 0%, rgba(255,206,52,1) 50%, rgba(101,181,129,1) 100%);
opacity: 1;
}
#rango .MuiSlider-track{
  background: transparent;
}
#footer #sucursales button{
  font-size: 12px;
  color: #8b8b8b;
  text-align: center;
}
#footer #sucursales button:not(:last-of-type):after{
  content:" > ";
}
#footer #sucursales button:last-of-type{
  font-size: 14px;
  color: #000;
}
#footer #menu button{
  border-radius: 5px;
  border:#8b8b8b 1px solid;
  background-color: transparent;
  margin: 0px 5px
}
.panel, .galeria{
  background-color: rgb(36, 36, 36);
  position: absolute;
  width: 100vw;
  height: 0vh;
  top:0px;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  color: #DCDCDC;
  z-index: 1000;
}
.galeria{
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
 .archivosico svg{
  height: 30px;
  width: 30px;
  color:#333;
}
.galeria .botonera .activo{
  color: #DCDCDC;
}
.galeria .botonera {
  justify-self: end;
  min-height: 35px;
}
.galeria img{
  max-height: 90%;
}
#panhelp{
  background-color: rgba(36, 36, 36, 0.8);
}
.panel .cerrar, .galeria .cerrar{
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 10px;
  color: #FFF;
  background-color: #000;
}
.panel.activo, #panregiones #region{
  height: calc(100vh - calc(100vh - 100%));
}
.panel ul{
  list-style: none;
  padding: 0px;
  margin: 0px;
  display: block;
  width: 100%;
}
.panel li{
  width: 100%;
}
.panel:not(#panregiones) li button{
  width: 100%;
  padding: 10px 20px;
  vertical-align: middle;
  font-size: 24px;
  color: #FFF;
  text-align: left;
  border-bottom: rgb(71, 71, 71) 1px solid;
}
.panel li button img{
  max-width: 50px;
}
#pancruce{
  display: flex;
  align-items: stretch;
}
#pancruce ul{
  display: flex;
  flex-direction: column;
}
#pancruce li{
  background-color: #DCDCDC;
  display: flex;
  align-items: stretch;
  width: auto;
  flex-grow: 1;
}
#pancruce li:not(:first-of-type){
  border-top: #8b8b8b 1px solid;
}
#pancruce li a{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  color: #555;
  flex-grow: 1;
}
#pancruce li a.active{
  color: #FFF;
  background-color: #000;
}
#pancruce li a svg{
  padding: 5px;
  width: 15px;
}
/* ANIMACIÓN */

 svg .svg-elem-1 {
  fill: transparent;
  -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
          transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
}

svg.active .svg-elem-1 {
  fill: rgb(218, 90, 39);
}

svg .svg-elem-2 {
  fill: transparent;
  -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s;
          transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s;
}

svg.active .svg-elem-2 {
  fill: rgb(218, 90, 39);
}

svg .svg-elem-3 {
  fill: transparent;
  -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1s;
          transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1s;
}

svg.active .svg-elem-3 {
  fill: rgb(218, 90, 39);
}

svg .svg-elem-4 {
  fill: transparent;
  -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s;
          transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s;
}

svg.active .svg-elem-4 {
  fill: rgb(218, 90, 39);
}

svg .svg-elem-5 {
  fill: transparent;
  -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2000000000000002s;
          transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2000000000000002s;
}

svg.active .svg-elem-5 {
  fill: rgb(218, 90, 39);
}

svg .svg-elem-6 {
  fill: transparent;
  -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3s;
          transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3s;
}

svg.active .svg-elem-6 {
  fill: rgb(235, 137, 36);
}

svg .svg-elem-7 {
  fill: transparent;
  -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.4000000000000001s;
          transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.4000000000000001s;
}

svg.active .svg-elem-7 {
  fill: rgb(235, 137, 36);
}

svg .svg-elem-8 {
  fill: transparent;
  -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.5s;
          transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.5s;
}

svg.active .svg-elem-8 {
  fill: rgb(235, 137, 36);
}

svg .svg-elem-9 {
  fill: transparent;
  -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.6s;
          transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.6s;
}

svg.active .svg-elem-9 {
  fill: rgb(235, 137, 36);
}

svg .svg-elem-10 {
  fill: transparent;
  -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7000000000000002s;
          transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7000000000000002s;
}

svg.active .svg-elem-10 {
  fill: rgb(0, 0, 0);
}

svg .svg-elem-11 {
  fill: transparent;
  -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.8s;
          transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.8s;
}

svg.active .svg-elem-11 {
  fill: rgb(0, 0, 0);
}

svg .svg-elem-12 {
  fill: transparent;
  -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.9000000000000001s;
          transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.9000000000000001s;
}

svg.active .svg-elem-12 {
  fill: rgb(0, 0, 0);
}

svg .svg-elem-13 {
  fill: transparent;
  -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2s;
          transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2s;
}

svg.active .svg-elem-13 {
  fill: rgb(0, 0, 0);
}

svg .svg-elem-14 {
  fill: transparent;
  -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.1s;
          transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.1s;
}

svg.active .svg-elem-14 {
  fill: rgb(0, 0, 0);
}

svg .svg-elem-15 {
  fill: transparent;
  -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.2s;
          transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.2s;
}

svg.active .svg-elem-15 {
  fill: rgb(0, 0, 0);
}

svg .svg-elem-16 {
  fill: transparent;
  -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.3s;
          transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.3s;
}

svg.active .svg-elem-16 {
  fill: rgb(218, 90, 39);
}

svg .svg-elem-17 {
  fill: transparent;
  -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.4000000000000004s;
          transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.4000000000000004s;
}

svg.active .svg-elem-17 {
  fill: rgb(218, 90, 39);
}

svg .svg-elem-18 {
  fill: transparent;
  -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.5s;
          transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.5s;
}

svg.active .svg-elem-18 {
  fill: rgb(0, 0, 0);
}

svg .svg-elem-19 {
  fill: transparent;
  -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.6s;
          transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.6s;
}

svg.active .svg-elem-19 {
  fill: rgb(0, 0, 0);
}

svg .svg-elem-20 {
  fill: transparent;
  -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.7s;
          transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.7s;
}

svg.active .svg-elem-20 {
  fill: rgb(139, 94, 60);
}

svg .svg-elem-21 {
  fill: transparent;
  -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.8s;
          transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.8s;
}

svg.active .svg-elem-21 {
  fill: rgb(139, 94, 60);
}

svg .svg-elem-22 {
  fill: transparent;
  -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.9000000000000004s;
          transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.9000000000000004s;
}

svg.active .svg-elem-22 {
  fill: rgb(218, 90, 39);
}

svg .svg-elem-23 {
  fill: transparent;
  -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3s;
          transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3s;
}

svg.active .svg-elem-23 {
  fill: rgb(218, 90, 39);
}

svg .svg-elem-24 {
  fill: transparent;
  -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.1000000000000005s;
          transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.1000000000000005s;
}

svg.active .svg-elem-24 {
  fill: rgb(235, 137, 36);
}

svg .svg-elem-25 {
  fill: transparent;
  -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.2s;
          transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.2s;
}

svg.active .svg-elem-25 {
  fill: rgb(235, 137, 36);
}

svg .svg-elem-26 {
  fill: transparent;
  -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.3s;
          transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.3s;
}

svg.active .svg-elem-26 {
  fill: rgb(235, 137, 36);
}

svg .svg-elem-27 {
  fill: transparent;
  -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.4000000000000004s;
          transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.4000000000000004s;
}

svg.active .svg-elem-27 {
  fill: rgb(235, 137, 36);
}

svg .svg-elem-28 {
  fill: transparent;
  -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.5s;
          transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.5s;
}

svg.active .svg-elem-28 {
  fill: rgb(235, 137, 36);
}

svg .svg-elem-29 {
  fill: transparent;
  -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.6000000000000005s;
          transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.6000000000000005s;
}

svg.active .svg-elem-29 {
  fill: rgb(235, 137, 36);
}

svg .svg-elem-30 {
  fill: transparent;
  -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.7s;
          transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.7s;
}

svg.active .svg-elem-30 {
  fill: rgb(235, 137, 36);
}

svg .svg-elem-31 {
  fill: transparent;
  -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.8s;
          transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.8s;
}

svg.active .svg-elem-31 {
  fill: rgb(235, 137, 36);
}

svg .svg-elem-32 {
  fill: transparent;
  -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.9000000000000004s;
          transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.9000000000000004s;
}

svg.active .svg-elem-32 {
  fill: rgb(235, 137, 36);
}

svg .svg-elem-33 {
  fill: transparent;
  -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 4s;
          transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 4s;
}

svg.active .svg-elem-33 {
  fill: rgb(235, 137, 36);
}

svg .svg-elem-34 {
  fill: transparent;
  -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 4.1000000000000005s;
          transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 4.1000000000000005s;
}

svg.active .svg-elem-34 {
  fill: rgb(235, 137, 36);
}

svg .svg-elem-35 {
  fill: transparent;
  -webkit-transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 4.2s;
          transition: fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 4.2s;
}

svg.active .svg-elem-35 {
  fill: rgb(235, 137, 36);
}
.MuiSlider-colorPrimary{
  color: #333!important;
}
/* ANIMACIÓN */


/*Tablas*/
.foto{
  position: relative;
}
.foto span{
  position: absolute;
  background-color: darkslategrey;
  height: 20px;
  width: 20px;
  right: -8px;
  top:-2px;
  line-height: 20px;
  color: #c5c5c5;
  border-radius: 50%;
}
.pdf svg .st0{
  fill:#8d0b06;
}
th{
  background-color: rgb(94, 94, 94);
  color: #ffffff;
  font-size: 22px;
}
#pantalla3 thead th:first-of-type{
  font-size: 32px;
  color: #000;
  background-color: transparent;
  width: 300px;
  border-top: #000 solid 2px;
}
table{
  font-size: 12px;
  margin-bottom: 20px;
  padding-top: 0px;
}
#pantalla7 td:first-of-type .tdBefore, #pantalla17b td:first-of-type .tdBefore, #pantalla8 td:first-of-type .tdBefore {
  display: none;
}
#pantalla8{
  flex-direction: row;
}
#pantalla8 td:first-of-type {
  background-color: transparent;
}
.col{
  flex: 1;
  width: 50%;
}
#pantalla8 td button{
  width: 100%;
  background-color: #c5c5c5;
  padding: 10px;
  text-align: left;
}
#pantalla8 td button.active{
  background-color: #DA5A27;
}
.grid{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  grid-auto-rows: minmax(100px, auto);
}
.pos-neg{
  display:flex;
  align-items: center;
  padding: 20px;
  width: 100%;
  background-color: rgb(94, 94, 94);
}
.pos-neg svg{
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.pos-neg.pos{
  color: rgba(101,181,129,1);
}
.pos-neg.neg{
  color: rgba(253,102,95,1);
}
.cont_grafica{
  width: 150px;
  height: 150px;
  margin: auto;
}
td{
  padding: 5px 4px;
  vertical-align: middle;
  font-size: 18px;
}
tr:nth-child(even) {
  background-color: #c5c5c5;
}
td:first-of-type{
  background-color: #b6b6b6;
  color: #000;
  font-weight: bold;
}
td:not(:first-of-type){
  text-align: center;
}
/* inspired by: https://css-tricks.com/responsive-data-tables/ */
.responsiveTable {
  width: 100%;
  flex: 1;
}

.responsiveTable td .tdBefore {
  display: none;
}
.graph {
  border: #000 1px solid;
  text-align: center;
  padding: 3px;
  }
.graph h4{
text-align: center;
margin: 0px;
background-color: #000;
color: #FFF;
}
.graph .pie{
  font-weight: bold;
}
.totales{
  font-size: 24px;
  padding: 3px;
  font-weight: bold;
  background-color: #000;
  padding: 3px;
}

@media screen and (max-width: 810px) {
  #pantalla8 {
    flex-direction: column;
  }
  #pantalla8 td button {
    padding: 5px;
  }
  #pantalla8 td {
    padding: 2px;
  }
  #pantalla8 .col {
    width: 100%;
    overflow: auto;
  }
  #pantalla8 .col:first-of-type {
    flex:0.5;
  }
  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    grid-auto-rows: minmax(100px, auto);
  }
  .pos-neg {
    padding: 2px;
    width: 100%;
  }
  /*
    Force table elements to not behave like tables anymore
    Hide table headers (but not display: none;, for accessibility)
  */
  td:first-of-type .tdBefore {
    font-size: 18px;
    color: #FFF;
    background-color: #000;
  }

  tr:nth-child(even) {
    background-color: transparent;
  }

  td:nth-child(even) {
    background-color: #c5c5c5;
  }

  .responsiveTable table,
  .responsiveTable thead,
  .responsiveTable tbody,
  .responsiveTable th,
  .responsiveTable td,
  .responsiveTable tr {
    display: block;
  }

  .responsiveTable thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
    border-bottom: 2px solid #333;
  }

  .responsiveTable tbody tr {
    /*border: 1px solid #000;*/
    padding: .25em;
  }

  .responsiveTable td.pivoted {
    /* Behave like a "row" */
    border: none !important;
    position: relative;
    /*padding-left: calc(50% + 10px) !important;*/
    text-align: left !important;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    display: flex;
  }

  .responsiveTable td .tdBefore {
    /* Now like a table header */
    display: block;

    /* Top/left values mimic padding */
    left: 1rem;
    width: calc(50% - 20px);
    white-space: pre-wrap;
    overflow-wrap: break-word;
    text-align: left !important;
    font-weight: 600;
  }
}

@media screen and (max-width: 375px) {
  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
    grid-auto-rows: minmax(100px, auto);
  }
}
.alerts{
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
}
.alerts .bell-container {
  position: relative;
  cursor: pointer;
}

.alerts .bell-icon {
  font-size: 32px;
}

.alerts .bell-icon.animated {
  animation: bell-animation 1s infinite;
}

@keyframes bell-animation {
  0%, 100% { transform: rotate(0); }
  25% { transform: rotate(-15deg); }
  75% { transform: rotate(15deg); }
}

.alerts .badge {
  position: absolute;
  top: -9px;
  right: -9px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 8px;
  font-size: 20px;
}

.modalalerts {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 4px;
  outline: none;
  z-index: 10000;
  width: 80%;
  height: 80%;
  max-width: 800px;
  max-height: 600px;
}

.overlayalerts {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 9999;
}

.modalalerts h2 {
  margin-top: 0;
  font-size: 24px;
  text-align: center;
}

.modalalerts button {
  display: inline-block;
  margin: 10px 5px;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #007BFF;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.modalalerts button:hover {
  background-color: #0056b3;
}
.modalalerts ul {
  list-style-type: none;
  padding: 0;
}

.modalalerts li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.modalalerts li.read {
  background-color: #f0f0f0;
  color: gray;
}

.modalalerts li.unread {
  background-color: #fff;
  color: black;
}

.modalalerts li button {
  margin-left: 10px;
  padding: 5px 10px;
  font-size: 14px;
  color: white;
  background-color: #28a745;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.modalalerts li button:hover {
  background-color: #218838;
}